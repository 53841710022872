'use client';

import Banner from '@/components/common/banner/Banner';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import HeaderImage from '../../../public/assets/images/home_header.png';
import Logo from '../../../public/assets/images/jobreel_logo.svg';
import {
  getCurrentBusiness,
  getCurrentProfile,
  getTokenPayload,
} from '@/lib/utils/functions/authentication';

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    const profile = getCurrentProfile();
    const business = getCurrentBusiness();
    const { userId } = getTokenPayload();

    if (!userId) {
      return;
    }

    if (userId && !profile && !business) {
      router.push(`/onboarding/mode`);
    }

    if (business) {
      return router.push(`/app/my-jobs`);
    } else if (profile) {
      return router.push(`/app/inbox`);
    }
  }, [router]);

  const onClick = useCallback(() => {
    const profile = getCurrentProfile();
    const business = getCurrentBusiness();
    const { userId } = getTokenPayload();

    if (!userId) {
      router.push(`/auth?from=signin`);
    } else if (!!business) {
      router.push(`/app/my-jobs`);
    } else if (!!profile) {
      router.push(`/app/inbox`);
    }
  }, [router]);

  return (
    <>
      <main className='flex h-[calc(100svh-80px)] flex-1 flex-col items-center justify-between bg-royal-blue'>
        <div className={'ld:w-1/4 flex flex-1 flex-col p-4'}>
          <div
            className={
              'm-auto flex w-3/4 flex-1 flex-col items-center justify-center md:w-4/12'
            }
          >
            <Image
              src={HeaderImage}
              alt={'jobreel header image'}
              aria-label={'Group of person in bubbles'}
              className={'h-auto w-full'}
            />
            <Logo className={'my-8 h-auto w-full fill-current text-white'} />
          </div>
          <div className={'flex flex-col'}>
            <button
              onClick={onClick}
              className={
                'my-6 flex w-full items-center justify-center rounded-full bg-ruby py-2 text-center font-moderat text-jb-base font-bold text-white'
              }
            >
              Continue with Phone Number
            </button>
            <p
              className={'font-regular mt-4 text-center text-jb-xs text-white'}
            >
              By tapping {'"Continue"'}, you agree to our{' '}
              <a href={'https://jobreel.io/tos'} target={'_blank'}>
                Terms of service
              </a>{' '}
              and agree you are at least 18 years of age. Learn how we process
              your data in our{' '}
              <a href={'https://jobreel.io/privacy'} target={'_blank'}>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href={''} target={'_blank'}>
                Cookies Policy
              </a>
              .
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
