import {
  getCurrentBusiness,
  getCurrentProfile,
  getUserId,
} from '@/lib/utils/functions/authentication';
import { datadogLogs, datadogRum } from './datadog';

type ErrorWithStack = Error & { stack?: string };

const isClient = () => typeof window !== 'undefined';

const getBaseContext = () => {
  if (!isClient()) return {};
  return {
    user: getUserId(),
    business: getCurrentBusiness(),
    profile: getCurrentProfile(),
    isLoggedIn: !!getUserId(),
  };
};

const logger = {
  info: (message: string, context: Record<string, any> = {}) => {
    if (!isClient()) return;
    const infoContext = { ...context, ...getBaseContext() };
    if (process.env.NODE_ENV === 'development') {
      console.info(message, infoContext);
    }
    datadogLogs.logger.info(message, infoContext);
    datadogRum.addAction(message, infoContext);
  },

  warn: (message: string, context: Record<string, any> = {}) => {
    if (!isClient()) return;
    const warnContext = { ...context, ...getBaseContext() };
    console.warn(message, warnContext);
    datadogLogs.logger.warn(message, warnContext);
  },

  error: (
    error: ErrorWithStack | string,
    customMessage?: string | null,
    context: Record<string, any> = {}
  ) => {
    if (!isClient()) return;
    const errorObject: ErrorWithStack =
      typeof error === 'string' ? new Error(error) : error;
    const logMessage = customMessage || errorObject.message;

    const errorContext = {
      ...context,
      ...getBaseContext(),
      error: customMessage,
    };

    console.error(logMessage, errorObject, errorContext);

    // Log to Datadog Logs
    datadogLogs.logger.error(
      logMessage,
      {
        ...context,
        ...getBaseContext(),
        error: {
          name: errorObject.name,
          message: errorObject.message,
          stack: errorObject.stack,
          customMessage: customMessage,
        },
      },
      errorObject
    );

    // Log to Datadog RUM
    datadogRum.addError(errorObject, errorContext);
  },
};

export default logger;
